.contact-container {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 200px;
    display: flex;
}
.contact-text {
    margin: auto;
    color: #bababa;
    margin-top: -50px;
    line-height: 2rem;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .contact-text > p {
        width: auto;
    }
}