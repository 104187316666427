.body-container {
    color: white;
    height: auto;
    margin: auto;
}
.content-section {
    max-width: 1536px;
    padding: 200px 150px 0 150px;
    margin: auto;
}
.content-headers {
    font-family: Roboto;
    color: #bababa;
    font-size: 2.5rem;
    font-weight: 900;
    letter-spacing: 10px;
    padding-bottom: 100px;
}
.scroll {
    scroll-margin-top: 200px;
}
.contact {
    text-align: center;
    color: #18ffff;
}
@media screen and (max-width: 900px) {
    .content-section {
        min-width: auto;
        padding: 150px 15px 0 15px;
    }
    .body-container {
        min-width: auto;
        margin: auto;
    }
}