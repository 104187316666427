.projects {
    padding-bottom: 200px;
}
.project-container {
    font-family: 'Roboto', sans-serif;
    text-align: justify;
    margin-bottom: 200px;
}
.project-title {
    font-family: monospace;
    color: #18ffff;
    font-size: 2rem;
    text-align: left;
    padding-bottom: 30px;
}
.content-container {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: #bababa;
}
.content-hover:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: rgba(29, 29, 29, 0.705);
    border-radius: 20px;
}
.img-container {
    height: 500px;
    border: 3px solid #18ffff;
    transition: transform .2s;
    border-radius: 20px;
}
.mobile-img {
    border-radius: 40px;
    margin-right: 200px;
}
.text-container {
    width: 50%;
}
.text-container > p, ul {
    line-height: 1.5rem;
    padding-bottom: 10px;
}
.text-container > ul {
    padding-left: 20px;
    list-style-type: "-";
}
.text-container > ul > li {
    padding-left: 15px;
}
.text-headers {
    font-size: 1rem;
    font-weight: 500;
    color: #18ffff;
    padding: 15px 0 15px 0;
}
.zoom:hover {
    transform: scale(1.05);
}

/* STYLING FOR VIDEO PLAYER */
.video-container {
    display: flex;
    position: fixed;
    top: 25%;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    z-index: 10;
}

video {
    width: 70%;
    min-width: 300px;
    margin: auto;
    border: 4pt #18ffff solid;
    border-radius: 10px;
}

/* MOBILE STYLING */
@media screen and (max-width: 900px) {
    .content-container {
        flex-wrap: wrap;
        width: auto;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }
    .text-container, .project-title {
        width: auto;
        margin: auto;
    }
    .img-container {
        width: 80%;
        height: auto;
        margin: 50px auto auto auto;
        max-width: 350px;
    }
    .mobile-img {
        width: 50%;
        height: auto;
        margin-right: auto;
        border-radius: 40px;
        max-width: 170px;
    }
}

/* TABLET/INTERMEDIATE RANGE RESPONSIVE STYLING */
@media screen and (min-width: 901px) and (max-width: 1600px) {
    .content-container {
        flex-wrap: wrap;
        width: auto;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }
    .text-container, .project-title {
        width: auto;
        padding-bottom: 50px;
    }
    .img-container {
        width: 25%;
        height: auto;
        margin-top: 50px;
        min-width: 420px;
    }
    .mobile-img {
        width: 20%;
        min-width: 248px;
        height: auto;
        border-radius: 40px;
        margin: 0
    }
}
