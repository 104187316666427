.about-container {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 600px;
    display: flex;
}
.about-container > div > p > span {
    color: #18ffff;
}
.text-section {
    width: 50%;
}
.about-image-container {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px
}
.aboutH1 {
    font-weight: 900;
    font-size: 4.5rem;
    padding-bottom: 20px;
}
.aboutH2 {
    font-family: monospace;
    color: #18ffff;
    padding-bottom: 20px;
    font-size: 1rem;
}
.aboutP {
    font-size: 1rem;
    color: #bababa;
    font-weight: 300;
    line-height: 1.5rem;
    text-align: justify;
    padding-bottom: 15px;
}
.about-svg {
    width: 300px;
    height: auto;
}
.skills-list {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0 20px 0;
}
.skills-list > li {
    padding: 10px 10px 10px 0;
}
.skills-chip {
    line-height: 1.2rem;
}
.skills-chip > svg {
    fill: white;
}
@media screen and (max-width: 1100px) {
    .about-container  {
      flex-wrap: wrap;
      margin-bottom: 200px;
    }
    .text-section {
        width: auto;
        margin: auto;
    }
    .aboutP {
        width: auto;
        margin: auto;
    }
    .about-image-container {
        margin-top: 50px;
    }
    .about-svg {
        width: 100%;
        max-width: 300px;
    }
    .aboutH1 {
        font-size: 4rem;
    }
}